import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ZodiacingSightsExplorer from "./ZodiacingSightsExplorer/ZodiacingSightsExplorer";
import CurrencyExchangeTool from "./CurrencyExchangeTool/CurrencyExchangeTool";

export default function App() {
  const [displayForm, setDisplayForm] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // 當頁面被隱藏或切換 tab 時
        console.log("頁面已隱藏");
      } else if (document.visibilityState === "visible") {
        // 當頁面再次顯示時重新整理
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // 清理事件監聽器
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* <Route
          path="/zodiacing-sights-explorer"
          element={
            <ZodiacingSightsExplorer
              displayForm={displayForm}
              setDisplayForm={setDisplayForm}
            />
          }
        /> */}
        <Route
          path="/"
          // path="/currency-exchange"
          element={
            <p>
              謝謝Amy 這段時間我真的很開心 祝福你幸福快樂 阿公一定會健健康康的
              工作順利 謝謝：）
            </p>
          }
        />
      </Routes>
    </Router>
  );
}
